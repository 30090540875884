<template>
  <div class="BodyParts content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="仪器编号">
              <el-input
                placeholder="输入仪器编号搜索"
                v-model="InstrumentID"
              ></el-input>
            </el-form-item>
            <el-form-item label="类型编号">
              <el-input
                placeholder="输入类型编号搜索"
                v-model="CategoryID"
              ></el-input>
            </el-form-item>
            <el-form-item label="标准等级">
              <el-input
                placeholder="输入标准等级搜索"
                v-model="Name"
              ></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select
                v-model="Active"
                placeholder="请选择"
                @change="handleSearch"
              >
                <el-option
                  v-for="item in ActiveList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="addParts"
            >新增检测标准</el-button
          >
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="light"
        style="width: 100%"
      >
        <el-table-column label="仪器编号" prop="InstrumentID"></el-table-column>
        <el-table-column label="类型名称" prop="CategoryName"></el-table-column>
        <el-table-column label="起始值" prop="BeginValue"></el-table-column>
        <el-table-column label="结束值" prop="EndValue"></el-table-column>
        <el-table-column label="标准等级" prop="StandardID"></el-table-column>
        <el-table-column label="值状态" prop="ConditionName"></el-table-column>
        <el-table-column
          label="结果评语和建议"
          prop="Proposal"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="备注"
          prop="Remark"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="分数升降序" prop="OrderByAD">
          <template slot-scope="scope">{{
            scope.row.OrderByAD ? "升序" : "降序"
          }}</template>
        </el-table-column>
        <el-table-column label="是否有图片" prop="isPicture">
          <template slot-scope="scope">{{
            scope.row.isPicture ? "是" : "否"
          }}</template>
        </el-table-column>
        <el-table-column
          label="颜色"
          prop="BarColor"
          width="100px"
          style="padding: 0"
        >
          <template slot-scope="scope">
            <div
              class="color_000 dis_flex flex_x_center flex_y_center"
              :style="{
                'background-color': scope.row.BarColor,
                height: '32px',
                color: scope.row.textColor,
              }"
            >
              {{ scope.row.BarColor }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="editor(scope.row)" size="small"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="deleter(scope.row)"
              v-if="isDelete"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogtype == 1 ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      append-to-body
      :before-close="closeDialog"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="1">
          <div class="pad_15">
<el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          size="small"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="仪器编号" prop="InstrumentID">
                <el-select
                  v-model="ruleForm.InstrumentID"
                  placeholder="请选择"
                  class="width_220"
                >
                  <el-option
                    v-for="item in Instrumentlist"
                    :key="item.InstrumentID"
                    :label="item.Name"
                    :value="item.InstrumentID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型编号" prop="CategoryID">
                <el-select
                  v-model="ruleForm.CategoryID"
                  placeholder="请选择"
                  class="width_220"
                >
                  <el-option
                    v-for="item in CategoryIDList"
                    :key="item.CategoryID"
                    :label="item.Name"
                    :value="item.CategoryID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="起始值" prop="BeginValue">
                <el-input
                  clearable
                  v-model="ruleForm.BeginValue"
                  placeholder="请输入起始值"
                  class="width_220"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="结束值" prop="EndValue">
                <el-input
                  clearable
                  v-model.number="ruleForm.EndValue"
                  oninput="value=value.replace(/[^\d.]/g,'')"
                  placeholder="请输入结束值"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="标准等级" prop="StandardID">
                <el-input
                  clearable
                  v-model="ruleForm.StandardID"
                  placeholder="请输入标准等级"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="值状态" prop="ConditionID">
                <el-select
                  v-model="ruleForm.ConditionID"
                  placeholder="请选择"
                  class="width_220"
                >
                  <el-option
                    v-for="item in ConditionIDList"
                    :key="item.ConditionID"
                    :label="item.Name"
                    :value="item.ConditionID"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="结果评语和建议" prop="Proposal">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="请输入评语和建议"
                  v-model="ruleForm.Proposal"
                  >></el-input
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="备注" prop="Remark">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="请输入备注"
                  v-model="ruleForm.Remark"
                  >></el-input
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="分数升降序" prop="OrderByAD">
                <el-radio v-model="ruleForm.OrderByAD" :label="true"
                  >升序</el-radio
                >
                <el-radio v-model="ruleForm.OrderByAD" :label="false"
                  >降序</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="是否有图片" prop="isPicture">
                <el-radio v-model="ruleForm.isPicture" :label="true"
                  >是</el-radio
                >
                <el-radio v-model="ruleForm.isPicture" :label="false"
                  >否</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="有效性" prop="Active">
                <el-radio v-model="ruleForm.Active" :label="true"
                  >有效</el-radio
                >
                <el-radio v-model="ruleForm.Active" :label="false"
                  >无效</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="条颜色" prop="BarColor">
                <el-color-picker v-model="ruleForm.BarColor"></el-color-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
          </div>
          </el-tab-pane>
          <el-tab-pane label="推荐商品" name="2">
          <el-row class="marbm_10">
            <el-col :span="6">
              <el-input
                placeholder="输入关键字查询"
                clearable
                v-model="filterProjectName"
                size="small"
              ></el-input>
            </el-col>
            <el-col :span="6" :offset="1">
              <el-button type="primary" size="small" @click="addSetDetails"
                >添加</el-button
              >
            </el-col>
          </el-row>
          <el-table size="small" :data="
              goodsRangeList.filter(
                (data) =>
                  !filterProjectName ||
                  data.Name.toLowerCase().includes(
                    filterProjectName.toLowerCase()
                  )
              )" max-height="400">
              <el-table-column
              prop="Name"
              label="商品名称"
            ></el-table-column>
            <el-table-column
              prop="TypeName"
              label="商品类型"
            ></el-table-column>
            <el-table-column prop="Amount" label="数量">
              <template slot-scope="scope">
                <el-input
                class="width_120"
                  size="small"
                  type="number"
                  min="1"
                  v-model.number="scope.row.Amount"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  @click="remove(1, scope.row, scope.$index)"
                  >删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
          </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          v-prevent-click
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 添加商品弹窗 -->
    <el-dialog title="添加推荐商品" :visible.sync="dialogScope" width="900px">
      <div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-collapse v-model="collapseIndex" accordion>
              <el-collapse-item title="产品" name="1">
                <el-input
                  v-model="productName"
                  placeholder="请输入关键词进行搜索"
                  clearable
                  size="small"
                ></el-input>
                <el-tree
                  ref="treeProduct"
                  :data="ProductList"
                  node-key="PID"
                  :props="{ children: 'Child', label: 'Name' }"
                  :filter-node-method="filterNode"
                  :default-checked-keys="productDefaultCheckedKeys"
                  :default-expanded-keys="productDefaultExpandedKeys"
                >
                  <div slot-scope="{ data }" style="width: 100%">
                    <el-row type="flex" justify="space-between">
                      <el-col :span="18">
                        <span>{{ data.Name }}</span>
                        <el-tag
                          class="marlt_5"
                          type="info"
                          size="mini"
                          v-if="data.IsGoods == 0"
                        >
                          分类
                        </el-tag>
                      </el-col>
                      <el-col :span="6" style="text-align: right">
                        <el-button
                          type="text"
                          @click="changeAll(1, data)"
                          size="mini"
                          v-if="data.IsGoods == 1"
                          >添加
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-tree>
              </el-collapse-item>
              <el-collapse-item title="项目" name="2">
                <el-input
                  v-model="projectName"
                  placeholder="请输入关键词进行搜索"
                  clearable
                  size="small"
                ></el-input>
                <el-tree
                  ref="treeProject"
                  :data="ProjectList"
                  node-key="PID"
                  :props="{ children: 'Child', label: 'Name' }"
                  :filter-node-method="filterNode"
                  :default-checked-keys="projectDefaultCheckedKeys"
                  :default-expanded-keys="projectDefaultExpandedKeys"
                >
                  <div slot-scope="{ data }" style="width: 100%">
                    <el-row type="flex" justify="space-between">
                      <el-col :span="18">
                        <span>{{ data.Name }}</span>
                        <el-tag
                          class="marlt_5"
                          type="info"
                          size="mini"
                          v-if="data.IsGoods == 0"
                        >
                          分类
                        </el-tag>
                      </el-col>
                      <el-col :span="6" style="text-align: right">
                        <el-button
                          type="text"
                          @click="changeAll(2, data)"
                          size="mini"
                          v-if="data.IsGoods == 1"
                          >添加
                        </el-button>
                      </el-col>
                    </el-row>
                  </div>
                </el-tree>
              </el-collapse-item>
            </el-collapse>
          </el-col>
          <el-col :span="16">
            <!--展示全部-->
            <el-table
              size="small"
              max-height="500px"
              :data="packageCardSelectAll"
            >
              <el-table-column prop="Name" label="商品名称"></el-table-column>
              <el-table-column
                prop="TypeName"
                label="商品类型"
              ></el-table-column>
              <!-- <el-table-column
                prop="OldPrice"
                label="销售价格"
              ></el-table-column> -->
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button
                    type="danger"
                    size="small"
                    @click="remove(2, scope.row, scope.$index)"
                    >删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogScope = false">取 消</el-button>
        <el-button size="small" type="primary" @click="addProjectSelect"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/CRM/Instrument/Standard";
import permission from "@/components/js/permission.js";
export default {
  name: "BodyParts",
  /**  引入的组件  */
  components: {},
  beforeCreate(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(to.meta.Permission, "检测标准");
    });
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      productDefaultCheckedKeys: [], //产品回显
      productDefaultExpandedKeys: [],
      projectDefaultCheckedKeys: [], //项目回显
      projectDefaultExpandedKeys: [],
      packageCardSelectAll: [], //选择的全部商品
      goodsRangeList: [],
      collapseIndex: "",
      dialogScope: false,
      filterProjectName: "",
      ProjectList: [],
      ProductList: [],
      activeName: "1",
      projectName: "",
      productName: "",
      InstrumentID: "",
      CategoryID: "",
      Name: "",
      Instrumentlist: [], //仪器编号
      CategoryIDList: [], //类型编号
      ConditionIDList: [], // 镜头列表
      Active: null,
      ActiveList: [
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
        { Name: "全部", Value: null },
      ],
      isDelete: true,
      dialogtype: 1,
      dialogVisible: false,
      tableData: [
        {
          Active: true,
          Name: "水分",
          MarkType: "001",
        },
      ],
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      ruleForm: {
        OrderByAD: true,
        isPicture: true,
        Active: true,
      },
      rules: {
        InstrumentID: [
          { required: true, message: "请选择仪器", trigger: "blur" },
        ],
        CategoryID: [
          { required: true, message: "请选择类型", trigger: "blur" },
        ],
        BeginValue: [
          { required: true, message: "请输入起始值", trigger: "blur" },
        ],
        EndValue: [
          { required: true, message: "请输入结束值", trigger: "blur" },
        ],
        StandardID: [
          { required: true, message: "请输入标准等级", trigger: "blur" },
        ],
        ConditionID: [
          { required: true, message: "请选择值状态", trigger: "blur" },
        ],
        OrderByAD: [{ required: true, message: "请选择排序规则" }],
        isPicture: [
          { required: true, message: "请选择是否有图片", trigger: "blur" },
        ],
        Active: [{ required: true, message: "请选择有效性", trigger: "blur" }],
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.Name.indexOf(value) !== -1;
    },
    //设置明细中选择的产品、项目...
    changeAll(index, data) {
      if (this.packageCardSelectAll.some((item) => item.ID == data.ID)) {
        this.$message.warning("该商品已添加!");
        return;
      }
      this.packageCardSelectAll.push({
        PID: data.PID,
        ID: data.ID,
        Amount: data.Price,
        OldPrice: data.Price,
        Price: data.Price,
        GoodsCategoryName: data.GoodsCategoryName,
        TotalPrice: "",
        TypeName: index === 1 ? "产品" : "项目",
        Name: data.Name,
      });
    },
    remove(type, row, indexall) {
      var that = this;
      if (type == 1) {
        that.goodsRangeList.splice(indexall, 1);
      } else {
        that.packageCardSelectAll.splice(indexall, 1);
      }
    },
    addProjectSelect() {
      var that = this;
      that.goodsRangeList = Object.assign([], that.packageCardSelectAll);

      that.dialogScope = false;
    },
    // 添加项目产品
    addSetDetails() {
      var that = this;
      that.packageCardSelectAll = Object.assign([], that.goodsRangeList);
      that.dialogScope = true;
    },
    handleSearch() {
      var that = this;
      var parmas = {
        PageNum: that.paginations.page,
        Name: that.Name,
        CategoryID: that.CategoryID,
        InstrumentID: that.InstrumentID,
        Active: that.Active,
      };
      API.all(parmas).then((res) => {
        if (res.StateCode == 200) {
          res.List.forEach((item) => {
            item.textColor = that.isColor(item.BarColor);
          });
          that.tableData = res.List;
          that.paginations.total = res.Total;
        }
      });
    },
    addParts() {
      this.dialogtype = 1;
      this.skinInstrument();
      this.getSkinCategory();
      this.getSkinCondition();
      this.dialogVisible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    deleter(row) {
      this.$confirm("此操作将永久删除此检测项目, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已删除",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    editor(row) {
      const {
        BarColor,
        isPicture,
        Remark,
        Proposal,
        ConditionID,
        StandardID,
        EndValue,
        BeginValue,
        InstrumentID,
        CategoryID,
        OrderByAD,
        Active,
      } = row;
      this.ruleForm = {
        BarColor,
        isPicture,
        Remark,
        Proposal,
        ConditionID,
        StandardID,
        EndValue,
        BeginValue,
        InstrumentID,
        CategoryID,
        OrderByAD,
        Active,
      };
      this.dialogtype = 2;
      this.dialogVisible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.clearValidate();
      }
    },
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.handleSearch();
    },
    closeDialog() {
      this.ruleForm = {
        OrderByAD: true,
        isPicture: true,
        Active: true,
      };
      this.goodsRangeList = [];
      this.dialogVisible = false;
    },
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createParts();
          } else {
            that.updateParts();
          }
        } else {
          that.activeName = "1";
        }
      });
    },
    createParts() {
      var that = this;
      API.create(that.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已添加!");
          that.closeDialog();
          that.handleSearch();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    updateParts() {
      var that = this;
      API.update(that.ruleForm).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("已修改!");
          that.closeDialog();
          that.handleSearch();
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 仪器编号
    skinInstrument() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinInstrument(params).then((res) => {
        if (res.StateCode == 200) {
          that.Instrumentlist = res.Data;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 皮肤类型
    getSkinCategory() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinCategory(params).then((res) => {
        if (res.StateCode == 200) {
          that.CategoryIDList = res.List;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    // 值状态
    getSkinCondition() {
      var that = this;
      var params = {
        Name: "",
        Active: true,
      };
      API.skinCondition(params).then((res) => {
        if (res.StateCode == 200) {
          that.ConditionIDList = res.List;
        } else {
          that.$message.error(res.Message);
        }
      });
    },
    //传入一种背景色 获取辨识度最好的字体颜色(黑色或白色),颜色可修改
    isColor(color) {
      var lightness = "";
      if (color.toString().charAt(0) == "#") {
        let useColorValue = color.slice(1);
        let rColor = useColorValue.slice(0, 2);
        let gColor = useColorValue.slice(2, 4);
        let bColor = useColorValue.slice(4);
        let rColorValue = parseInt(rColor, 16).toString();
        let gColorValue = parseInt(gColor, 16).toString();
        let bColorValue = parseInt(bColor, 16).toString();
        lightness =
          rColorValue * 0.299 + gColorValue * 0.587 + bColorValue * 0.114;
      } else if (color.toString().charAt(0).toUpperCase() == "R") {
        var torgb = color.slice(3, color, length - 1).split(",");
        let rColorValue = torgb[0];
        let gColorValue = torgb[1];
        let bColorValue = [2];

        lightness =
          rColorValue * 0.299 + gColorValue * 0.587 + bColorValue * 0.114;
      }

      return lightness < 128 ? "white" : "black";
    },
    // 获取产品
    productData: function () {
      var that = this;
      let params = {
        productName: that.productName,
      };
      API.savingCardProduct(params).then((res) => {
        if (res.StateCode == 200) {
          that.ProductList = res.Data;
          that.setRecursion(res.Data);
        } else {
          this.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取项目
    projectData: function () {
      var that = this;
      API.savingCardProject().then((res) => {
        if (res.StateCode == 200) {
          that.ProjectList = res.Data;
          that.setRecursion(res.Data);
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
  },
  /** 监听数据变化   */
  watch: {
     productName(val) {
      this.$refs.treeProduct.filter(val);
    },
    projectName(val) {
      this.$refs.treeProject.filter(val);
    },
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.isDelete = permission.permission(
      this.$route.meta.Permission,
      "检测标准"
    );
    this.handleSearch();
    this.skinInstrument();
    this.getSkinCategory();
    this.getSkinCondition();
    this.productData();
    this.projectData();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
.dialog_header {
  padding: 12px 0;
}
.width_120{
  width: 120px;
}
</style>
